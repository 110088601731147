import {ICategory} from '../types/category';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IFilterConfigDTO} from '../types/galleryTypes';
import {DEFAULT_COLLECTION_ID} from '../constants';
import {RouterPrefix} from '@wix/wixstores-client-core';

export class CategoriesService {
  public visibleCategories: ICategory[];
  private categoryListConfig: IFilterConfigDTO;

  constructor(private readonly allCategories: ICategory[], private readonly siteStore: SiteStore) {}

  public updateVisibleCategories({
    categoryListConfig,
    shouldUseCategoryListConfig,
  }: {
    categoryListConfig?: IFilterConfigDTO;
    shouldUseCategoryListConfig: boolean;
  }) {
    if (categoryListConfig) {
      this.categoryListConfig = categoryListConfig;
    }

    let categories = [...this.allCategories];
    if (shouldUseCategoryListConfig && this.categoryListConfig !== undefined) {
      const sortedIds = [];
      const selectedCategoriesFromConfig = this.categoryListConfig.selected.reduce((obj, item) => {
        obj[item.id] = null;
        sortedIds.push(item.id);
        return obj;
      }, {});

      categories = categories
        .filter((c) => c.visible && c.id in selectedCategoriesFromConfig)
        .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
    } else {
      categories = categories.filter((c) => c.visible).sort((a, b) => a.name.localeCompare(b.name));
      const defaultCategoryIndex = categories.findIndex((c) => c.id === DEFAULT_COLLECTION_ID);
      if (defaultCategoryIndex !== -1) {
        categories.unshift(categories.splice(defaultCategoryIndex, 1)[0]);
      }
    }

    this.visibleCategories = categories.map((c) => {
      return {
        ...c,
        categoryUrl: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${c.slug}`,
      };
    });
  }

  public getCategoryBySlug(slug?: string) {
    return this.allCategories.find((c) => c.slug === slug) ?? this.visibleCategories[0];
  }
}
